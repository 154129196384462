import React from "react";
import { navigate } from "gatsby";
import { AuthProvider } from "../../../components/Auth/auth";
import SimpleSpring from "../../../components/Transitions/transitions";
import styles from "../../../styles/Signin.module.scss";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import LoginLayout from "../../../components/LoginLayout";
import ConnectInstagram from "../../../components/Onboarding/ConnectInstagram";
import { LocationTypes } from "../../../components/Onboarding/ConnectBidddy";
import { PathTypes } from "../../../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/Types/PathTypes";

const ConnectInstagramPage = ({ location }) => {
  const locationPath =
    location.state && location.state.location ? location.state.location : null;

  // New Path
  const fromPath =
    location.state && location.state.fromPath ? location.state.fromPath : null;

  const handleClose = () => {
    if (locationPath && locationPath === LocationTypes.Account) {
      navigate(`/dashboard/account/`);
      return;
    }
    // TODO: add proper back path
    if (fromPath === PathTypes.DASHBOARD) {
      navigate("/dashboard/");
    } else {
      navigate("/dashboard/auctions/");
    }
  };

  const handleBack = () => {
    if (location === LocationTypes.Account) {
      navigate(`/dashboard/account/`);
      return;
    }

    if (fromPath === PathTypes.DASHBOARD) {
      navigate("/dashboard/");
    } else {
      navigate("/dashboard/auctions/");
    }
  };

  return (
    <SimpleSpring location={location}>
      <SignUpHeader
        hideBack={false}
        onBack={() => handleBack()}
        title="Connect to Instagram"
        onClose={() => handleClose()}
      />
      <AuthProvider>
        <LoginLayout
          hideHeader
          title="Bidddy - Not Connected to Instagram Business Account"
        >
          <div className={styles.loginContainer}>
            <ConnectInstagram location={location} />
          </div>
        </LoginLayout>
      </AuthProvider>
    </SimpleSpring>
  );
};

export default ConnectInstagramPage;
