import React, { useEffect, useState } from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import { useSelector } from "react-redux";
import styles from "./Onboarding.module.scss";
import RoundedButton from "../Buttons/RoundedButton";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
} from "../../store/user/selectors";
import { Market } from "../../types/Market";
import Axios from "../../extensions/Axios";
import { event } from "../../lib/ga";
import { baseURL } from "../../utils/axios";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../store/user/actions";
import { useAppDispatch } from "../../store";
import LoadingSmall from "../Loading/LoadingSmall";
import { getAuction } from "../../store/auctionModal/selectors";
import { Auction } from "../../types/Auction";
import { MethodTypes } from "../../enums/MethodTypes";

const BIDFOOT_SKATEBOARD =
  "https://imgix.cosmicjs.com/0e1f9ba0-8c44-11ec-ae88-8982447d5ddf-bidfootholdskateboard-1.png";

const ConnectInstagram = ({ location }) => {
  const dispatch = useAppDispatch();
  const userInfo = useSelector((state) => selectUserInfo(state));
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const [localLoading, setLocalLoading] = useState(false);
  const [linkUrl, setLinkUrl] = useState(null);
  const [checkingAccounts, setCheckingAccounts] = useState(true);
  const [numberOfAvailableMarkets, setNumberOfAvailableMarkets] = useState(
    availableMarkets?.length
  );

  const toPath =
    location && location.state && location.state.toPath
      ? location.state.toPath
      : "";
  const originationPath =
    location && location.state && location.state.originationPath
      ? location.state.originationPath
      : "";

  useEffect(() => {
    setCheckingAccounts(true);
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
    dispatch(getUserInstagramAccounts());
    (async () => {
      const currentStateObject = {
        type: "CONNECT_INSTAGRAM",
        toPath,
        originationPath,
      };
      // Handle stuff.
      const response = await Axios.get(
        `${baseURL}/link/instagram?format=json&state=${encodeURIComponent(
          JSON.stringify(currentStateObject)
        )}`
      );
      setLinkUrl(response.data.url);
    })();
  }, []);

  useEffect(() => {
    setCheckingAccounts(false);
  }, [availableMarkets]);

  const openUrl = (url) => {
    event({
      action: "instagram_auth_attempt",
      params: {},
    });
    setLocalLoading(true);
    window.location.href = url;
  };

  const connect = () => {
    setLocalLoading(true);
    if (typeof window !== "undefined" && userInfo && linkUrl) {
      openUrl(linkUrl);
    }
  };

  const getTitle = () => {
    if (auction.type === MethodTypes.schedule) {
      return "Instagram Business Account";
    }
    return "Instagram Business or Creator Account";
  };

  return (
    <div
      className={cn([
        styles.howItWorksOnboardingContainer,
        styles.connectInstagramContainer,
        styles.defaultContainer,
      ])}
    >
      <div className={cn([styles.congratsImageContainer, styles.connectImage])}>
        <Imgix
          src={`${BIDFOOT_SKATEBOARD}?auto=format`}
          width={159}
          height={249}
          htmlAttributes={{
            alt: `Connect Account`,
            src: `${BIDFOOT_SKATEBOARD}?auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
        />
      </div>
      <h3>You&apos;re almost there...</h3>
      <h2>Let&apos;s Connect Bidddy to Instagram</h2>
      <p>
        It&apos;s easy and you only have to do it once. To help it go smoothly,
        you will need the following:
      </p>
      <div className={styles.whatYouNeedSection}>
        <h4>{getTitle()}</h4>
        <p>
          Your instagram account is either set up as a Personal, Business or
          Creator Account. Need help learning how to switch your account?{" "}
          <a
            href="/learn/switching-an-account/"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            Click here.
          </a>
        </p>
      </div>
      <div className={styles.whatYouNeedSection}>
        <h4>Facebook Page Linked to Account</h4>
        <p>
          Instagram uses Facebook to connect. Need help learning how to connect
          a Facebook Page to an Instagram Account?{" "}
          <a
            href="/learn/how-to-link-facebook-page/"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            Click here.
          </a>
        </p>
      </div>
      <RoundedButton
        extraClass={styles.largeButton}
        onClick={() => connect()}
        disabled={!linkUrl || checkingAccounts}
        id="submit"
      >
        {!checkingAccounts && !localLoading && "Connect Now"}
        {(checkingAccounts || localLoading) && <LoadingSmall />}
      </RoundedButton>
    </div>
  );
};

export default ConnectInstagram;
